import gql from 'graphql-tag'

export const EXPORT_ORDER_JOBS_MUTATION = gql`
  mutation ExportOrderJobs($ids: [ID!]!) {
    exportOrderJobs(ids: $ids) {
      success
      errors {
        field
        messages
      }
      url
    }
  }
`

export const CREATE_ORDER_MUTATION = gql`
  mutation CreateOrderMutation(
    $base: OrderBaseInputType!
    $cars: [OrderCarInputType!]!
    $pickup: OrderPickupInputType!
    $delivery: OrderDeliveryInputType!
    $returning: OrderReturningInputType!
    $summary: OrderSummaryInputType
  ) {
    createOrder(
      base: $base
      cars: $cars
      pickup: $pickup
      delivery: $delivery
      returning: $returning
      summary: $summary
    ) {
      order {
        id
        isSos
      }
      success
      errors {
        nonFieldErrors
        base {
          field
          messages
        }
        cars {
          field
          messages
        }
        pickup {
          field
          messages
        }
        delivery {
          field
          messages
        }
        returning {
          field
          messages
        }
      }
    }
  }
`

export const UPDATE_ORDER_JOB_MUTATION = gql`
  mutation UpdateOrderJobMutation(
    $id: ID!
    $base: OrderBaseInputType!
    $car: OrderCarInputType!
    $pickup: OrderPickupInputType!
    $delivery: OrderDeliveryInputType!
    $returning: OrderReturningInputType
  ) {
    updateOrderJob(
      id: $id
      base: $base
      car: $car
      pickup: $pickup
      delivery: $delivery
      returning: $returning
    ) {
      orderJob {
        id
        isSos
      }
      success
      errors {
        nonFieldErrors
        base {
          field
          messages
        }
        car {
          field
          messages
        }
        pickup {
          field
          messages
        }
        delivery {
          field
          messages
        }
        returning {
          field
          messages
        }
      }
    }
  }
`

export const DELETE_ORDER_JOB_MUTATION = gql`
  mutation DeleteOrderJob($id: ID!) {
    deleteOrderJob(id: $id) {
      success
    }
  }
`

export const UPDATE_ORDER_JOB_FIELDS_MUTATION = gql`
  mutation UpdateOrderJobFieldsMutation(
    $id: ID!
    $comment: String
    $isSos: Boolean
    $isComplex: Boolean
  ) {
    updateOrderJobFields(
      id: $id
      comment: $comment
      isSos: $isSos
      isComplex: $isComplex
    ) {
      success
      orderJob {
        id
        comment
        isSos
        isComplex
      }
    }
  }
`

export const REQUEST_ORDER_JOB_PICKUP_CHANGE_MUTATION = gql`
  mutation RequestOrderJobPickupDatetimeChange(
    $orderJobId: ID!
    $pickupDate: String!
    $pickupHour: String!
  ) {
    requestOrderJobPickupDatetimeChange(
      orderJobId: $orderJobId
      pickupDate: $pickupDate
      pickupHour: $pickupHour
    ) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const ACCEPT_PICKUP_DATETIME_CHANGE_MUTATION = gql`
  mutation AcceptOrderJobPickupDatetimeChange(
    $orderJobChangeId: ID!
    $pickupDate: String!
    $pickupHour: String!
  ) {
    acceptOrderJobPickupDatetimeChange(
      orderJobChangeId: $orderJobChangeId
      pickupDate: $pickupDate
      pickupHour: $pickupHour
    ) {
      success
      errors {
        field
        messages
      }
    }
  }
`
// BULK ACTIONS
export const BULK_ORDER_JOB_DELETE_MUTATION = gql`
  mutation BulkOrderJobDelete($data: BulkOrderJobDeleteInputType!) {
    bulkOrderJobDelete(data: $data) {
      success
      errors {
        field
        messages
      }
    }
  }
`
export const BULK_ORDER_JOB_COMPLETE_MUTATION = gql`
  mutation BulkOrderJobComplete($data: BulkOrderJobCompleteInputType!) {
    bulkOrderJobComplete(data: $data) {
      success
      errors {
        field
        messages
      }
    }
  }
`
export const BULK_ORDER_JOB_TERMINATE_MUTATION = gql`
  mutation BulkOrderJobTerminate($data: BulkOrderJobTerminateInputType!) {
    bulkOrderJobTerminate(data: $data) {
      success
      errors {
        field
        messages
      }
    }
  }
`
