import React from 'react'
import { node } from 'prop-types'

export const ModalContext = React.createContext({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {},
})

export function ModalProvider(props) {
  const [state, setState] = React.useState({
    component: null,
    props: {},
  })

  const showModal = (component, props = {}) => setState({
    component,
    props,
  })

  const hideModal = () => setState({
    component: null,
    props: {},
  })

  return (
    <ModalContext.Provider value={{ ...state, showModal, hideModal }}>
      {props.children}
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = { children: node.isRequired }

export const ModalConsumer = ModalContext.Consumer

export const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, props, hideModal }) => (
      Component
        ? <Component {...props} onClose={hideModal} />
        : null
    )}
  </ModalConsumer>
)
