import React from 'react'
import { bool, string } from 'prop-types'
import { Route, Redirect } from 'react-router-dom'


function ProtectedRoute({ shouldRedirect, redirectTo, ...props }) {
  if (shouldRedirect) {
    return <Redirect to={redirectTo} />
  }
  return (
    <Route {...props} />
  )
}

ProtectedRoute.propTypes = {
  redirectTo: string.isRequired,
  shouldRedirect: bool.isRequired,
}

export default ProtectedRoute
