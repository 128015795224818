import React from 'react'
import { node } from 'prop-types'
import { makeStyles } from '@material-ui/styles'

import Logo from '../components/Logo'
import publicBGSrc from '../assets/img/public_bg.png'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    backgroundColor: theme.palette.colors.appBg,
    backgroundImage: `url(${publicBGSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    overflow: 'auto',
  },
  firstColumn: {
    width: 130,
    backgroundColor: theme.palette.colors.darker,
  },
  logoContainer: {
    width: 130,
    height: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.colors.dark,
  },
  secondColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: 65,
    paddingRight: 65,
  },
  contentBox: {
    minHeight: 620,
    marginTop: 20,
    marginBottom: 40,
  },
}))

export default function EmptyPageLayout({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.firstColumn}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
      </div>
      <div className={classes.secondColumn}>
        <div className={classes.contentBox}>
          {children}
        </div>
      </div>
    </div>
  )
}

EmptyPageLayout.propTypes = { children: node.isRequired }
