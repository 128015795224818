import jwtDecode from 'jwt-decode'

import { getAccessToken } from './auth'

const leeway = 20 // puffer to handle live token for server in sec

export function isTokenExpired() {
  const token = getAccessToken()
  if(!token) {
    return true
  }
  const { exp } = jwtDecode(token)
  // exp is in seconds
  const now = Math.floor(new Date().getTime()/1000)

  // console.log('[Util: isTokenExpired]', { exp, now, result: exp < now })

  return (exp - leeway) < now
}
