import React from 'react'
import { node, oneOf, string } from 'prop-types'
import cx from 'classnames'
import { NavLink as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'


// The usage of React.forwardRef will no longer be required for react-router-dom v6.
// see https://github.com/ReactTraining/react-router/issues/6056
export const AdapterLink = React.forwardRef((props, ref) => <RouterLink innerRef={ref} {...props} />)


const useStyles = makeStyles((theme) => ({
  primaryNavLink: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 600,
    padding: '14px 10px',
    '&:hover': { color: theme.palette.colors.primary },
  },
  primaryActive: {
    fontWeight: 700,
    backgroundColor: 'rgba(255,255,255,.1)',
    borderRadius: 5,
  },
  secondaryNavLink: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    fontSize: 17,
    lineHeight: '23px',
    fontWeight: 500,
    padding: '15px 0 12px',
    borderBottomWidth: 3,
    borderBottomStyle: 'solid',
    borderBottomColor: 'transparent',
  },
  secondaryActive: {
    fontWeight: 'bold',
    color: theme.palette.colors.dark,
    borderBottomColor: theme.palette.primary.main,
  },
}))


export default function NavLink({ className, styleVariant, ...props }) {
  const classes = useStyles()

  return (
    <Link
      color="inherit"
      className={cx(className, {
        [classes.primaryNavLink]: styleVariant === 'primary',
        [classes.secondaryNavLink]: styleVariant === 'secondary',
      })}
      activeClassName={cx({
        [classes.primaryActive]: styleVariant === 'primary',
        [classes.secondaryActive]: styleVariant === 'secondary',
      })}
      component={AdapterLink}
      exact
      underline="none"
      {...props}
    />
  )
}

NavLink.defaultProps = {
  className: '',
  styleVariant: 'primary',
}

NavLink.propTypes = {
  className: string,
  to: string.isRequired,
  children: node.isRequired,
  styleVariant: oneOf(['primary', 'secondary']),
}
