import { AUTH_QUERY } from '../graphql/queries'
import { LOGOUT_MUTATION } from '../graphql/mutations'


const TOKEN_NAME = 'apollo_auth_token'
const REFRESH_TOKEN_NAME = 'apollo_refresh_token'

export function setToken(response) {
  if (!response) {
    return
  }
  localStorage.setItem(TOKEN_NAME, response.token)
  localStorage.setItem(REFRESH_TOKEN_NAME, response.refreshToken)
}

export function clearToken() {
  localStorage.removeItem(TOKEN_NAME)
  localStorage.removeItem(REFRESH_TOKEN_NAME)
}

export function getAccessToken() {
  return localStorage.getItem(TOKEN_NAME)
}
export function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN_NAME)
}

export function hasToken() {
  return Boolean(getAccessToken())
}


// cache control
export function writeAuthQuery(client, isLoggedIn) {
  client.cache.writeQuery({
    query: AUTH_QUERY,
    data: {
      auth: {
        isLoggedIn,
        __typename: 'Auth',
      },
    },
  })
}

export function loginUser(client, response) {
  setToken(response)
  writeAuthQuery(client, true) // notify react app
}

export function logoutUser(client, needRevoke = false) {
  if (needRevoke) {
    const refreshToken = getRefreshToken()
    if (refreshToken) {
      client.mutate({
        mutation: LOGOUT_MUTATION,
        variables: { refreshToken },
      })
    }
  }

  clearToken()
  writeAuthQuery(client, false) // notify react app
}
