import gql from 'graphql-tag'

export const ADMIN_CREATE = gql`
  mutation AdminPartner($name: String!, $email: String!) {
    createAdmin(name: $name, email: $email) {
      admin {
        id
        name
        email
        status
      }
      errors {
        field
        messages
      }
    }
  }
`

export const ADMIN_DELETE = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(adminId: $id) {
      success
    }
  }
`

export const ADMIN_UPDATE = gql`
  mutation UpdateAdmin($id: ID!, $name: String!, $email: String!) {
    updateAdmin(id: $id, name: $name, email: $email) {
      admin {
        id
        name
        email
        status
      }
      errors {
        field
        messages
      }
    }
  }
`
