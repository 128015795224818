import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/styles'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: theme.palette.colors.bg2,
  },
}))

export default function PageLoading() {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <CircularProgress size={25} />
      <div style={{ marginLeft: '1em' }}>
        Oldal betöltése folyamatban...
      </div>
    </div>
  )
}
