import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'


import { paths, urls } from '../constants/urls'
import PageLoading from './PageLoading'

// Code splitting
const ProfileSettingsView = React.lazy(() => import('../views/ProfileSettingsView'))


export default function UserSettingsRoutes() {
  return (
    <React.Suspense fallback={<PageLoading />}>
      <Switch>
        <Route exact path={paths.profileSettings} component={ProfileSettingsView} />

        <Redirect to={urls.profileSettings} />
      </Switch>
    </React.Suspense>
  )
}
