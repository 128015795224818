import gql from 'graphql-tag'

export const ORDERS_LIST_QUERY = gql`
  query OrderJobsQuery(
    $first: Int!
    $after: String
    $search: String
    $mainPartnerId: ID
    $partnerUser: ID
    $fromDate: Date
    $toDate: Date
    $jobType: ID
    $jobStatus: String
    $order: String
  ) {
    orderJobs(
      first: $first
      after: $after
      search: $search
      mainPartnerId: $mainPartnerId
      partnerUser: $partnerUser
      fromDate: $fromDate
      toDate: $toDate
      jobType: $jobType
      jobStatus: $jobStatus
      order: $order
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        cursor
        node {
          id
          hasRequestedChanges
          mainPartner {
            id
            name
            shortName
          }
          partnerUserName
          partnerUserPhoneNumber
          partnerUserEmail
          orderedAt
          jobType {
            id
            name
          }
          isSos
          isComplex
          pickupPartnerName
          pickupPartnerAddressName
          pickupPartnerAddressShortName
          pickupDate
          pickupFromHour
          pickupToHour
          pickupUserName
          pickupUserPhoneNumber
          pickupUserEmail
          pickupComment
          deliveryPartnerName
          deliveryPartnerAddressName
          deliveryPartnerAddressShortName
          deliveryDate
          deliveryTime
          deliveryHour
          deliveryUserName
          deliveryUserPhoneNumber
          deliveryUserEmail
          deliveryComment
          registrationPlateNumber
          carModel {
            name
          }
          carMake {
            name
          }
          comment
          isReturn
          jobStatus
        }
      }
    }
  }
`

export const NEW_ORDERS_COUNT_QUERY = gql`
  query OrderJobsCountQuery($createdAtFrom: DateTime!) {
    orderJobCount: orderJobs(createdAtFrom: $createdAtFrom) {
      totalCount
    }
  }
`

export const CREATE_ORDER_OPTIONS_QUERY = gql`
  query CreateOrderOptions($isAdminUser: Boolean!) {
    currentMainPartner @skip(if: $isAdminUser) {
      id
      name
      allowedJobTypes {
        id
        name
        kind
      }
    }
    currentPartnerUser: me @skip(if: $isAdminUser) {
      id
      partnerUser {
        id
        name
        email
        phoneNumber
      }
    }
    mainPartners @include(if: $isAdminUser) {
      id
      name
      allowedJobTypes {
        id
        name
      }
    }
    clients @include(if: $isAdminUser) {
      mainPartner {
        id
      }
      partnerUser {
        id
        name
        email
        phoneNumber
      }
    }
    jobTypes @include(if: $isAdminUser) {
      id
      name
      kind
    }
    carMakes {
      id
      name
      carModels {
        id
        name
      }
    }
  }
`

export const SEARCH_CARS_QUERY = gql`
  query SearchCars($registrationPlateNumbers: [String!]!) {
    searchCars(registrationPlateNumbers: $registrationPlateNumbers) {
      registrationPlateNumber
      carMake {
        id
      }
      carModel {
        id
      }
    }
  }
`

export const PARTNER_ADDRESSES_AND_USERS = gql`
  query PartnerAddressesAndUsers($partnerId: ID!) {
    addresses(partnerId: $partnerId) {
      id
      name
      placeId
      site {
        shortName
      }
    }
    partnerUsers(partnerId: $partnerId) {
      id
      name
      phoneNumber
      email
    }
  }
`

export const RECOMMEND_EXTRA_RECIPIENTS_QUERY = gql`
  query RecommendExtraRecipients($partnerUserId: ID!) {
    recommendExtraRecipients(partnerUserId: $partnerUserId) {
      extraRecipients
    }
  }
`

export const ORDER_JOB_DETAILS_QUERY = gql`
  query OrderJobDetails($id: ID!, $isAdminUser: Boolean!) {
    groupedOrderJob(id: $id) {
      id
      isReturn
      returnJobId
      comment
      base {
        orderId
        mainPartnerId
        partnerUserId @include(if: $isAdminUser)
        jobTypeId
        isSos
        isComplex
      }
      car {
        registrationPlateNumber
        carMakeId
        carModelId
      }
      pickup {
        partnerId
        partnerName
        addressId
        addressLine
        addressPlaceId
        date
        fromHour
        toHour
        partnerUserId
        partnerUserName
        partnerUserPhoneNumber
        partnerUserEmail
        comment
      }
      delivery {
        partnerId
        partnerName
        addressId
        addressLine
        addressPlaceId
        date
        time
        hour
        partnerUserId
        partnerUserName
        partnerUserPhoneNumber
        partnerUserEmail
        comment
      }
      relatedJobs {
        id
        jobTypeName
        registrationPlateNumber
      }
      requestedChanges @include(if: $isAdminUser) {
        id
        requestedBy
        pickupDate
        pickupHour
      }
      relatedDocuments {
        name
        url
      }
      handovers {
        id
        direction
        frontCondition
        rightSideCondition
        leftSideCondition
        backCondition
        fieldImages {
          id
          imageUrl
          fieldName
          fieldNameHun
          fieldCondition
        }
      }
      returnData {
        serviceReadyDate
        serviceReadyHour
        returnPartnerAddressId
        returnPartnerAddressName
        returnPartnerAddressPlaceId
        returnPartnerUserId
        returnPartnerUserName
        returnPartnerUserPhoneNumber
        returnPartnerUserEmail
        returnDate
        returnHour
      }
    }
    orderJobAnalytics(id: $id) @include(if: $isAdminUser) {
      id
      createdAt
      createdBy {
        id
        name
        email
        role
      }
      type
      analyticsObjects {
        id
        relatedObject {
          id
          __typename
          ... on User {
            name
            role
          }
          ... on DriverNode {
            name
            emoji
          }
          ... on OrderJobStepNode {
            stepType {
              id
              name
            }
          }
          ... on OrderJobNode {
            jobType {
              id
              name
            }
            finishComment
            partnerUserName
            pickupPartnerName
            deliveryPartnerName
          }
        }
      }
    }
  }
`

export const LOAD_ORDER_JOB_CHANGE_REQUEST_INFO_QUERY = gql`
  query OrderJobChangeRequestInfo($id: ID!) {
    orderJob(id: $id) {
      id
      requestedChanges {
        id
        requestedBy
        pickupDate
        pickupHour
      }
    }
  }
`
