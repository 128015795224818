import gql from 'graphql-tag'

export const OrderJobNodeFragment = gql`
  fragment OrderJobNodeFragment on OrderJobNode {
    id
    mainPartner {
      id
      shortName
    }
    jobType {
      id
      name
      kind
    }
    isSos
    pickupPartnerName
    pickupPartnerAddressName
    pickupDate
    pickupFromHour
    pickupToHour
    deliveryPartnerName
    deliveryPartnerAddressName
    deliveryDate
    deliveryHour
    serviceReadyDate
    serviceReadyHour
    registrationPlateNumber
    returnPartnerAddressName
    returnDate
    returnHour
  }
`
export const OrderJobStepNodeFragment = gql`
  fragment OrderJobStepNodeFragment on OrderJobStepNode {
    id
    stepType {
      id
      name
    }
    duration
    plannedStart
    status
    position
    plannedFinish
    orderJob {
      id
      registrationPlateNumber
      mainPartner {
        id
        shortName
      }
      deliveryDate
      deliveryHour
      jobStatus
      # extra info for WAIT jobTypeKind to determine isStepLate()
      jobType {
        id
        kind
      }
      returnDate
      returnHour
      serviceReadyDate
    }
  }
`

export const OrderJobDetailsStepNodeFragment = gql`
  fragment OrderJobDetailsStepNodeFragment on OrderJobStepNode {
    # plannedFinish
    addressName
    driver {
      id
      name
      driverId
    }
    splitChild {
      id
    }
    splitParent {
      id
    }
    ...OrderJobStepNodeFragment
  }
  ${OrderJobStepNodeFragment}
`

export const OrderJobDetailsFragment = gql`
  fragment OrderJobDetailsFragment on OrderJobNode {
    ...OrderJobNodeFragment
    jobStatus
    steps {
      edges {
        node {
          ...OrderJobDetailsStepNodeFragment
        }
      }
    }
  }
  ${OrderJobNodeFragment}
  ${OrderJobDetailsStepNodeFragment}
`

export const OrderJobUpdateStepsFragment = gql`
  fragment OrderJobUpdateStepsFragment on OrderJobNode {
    id
    steps {
      edges {
        node {
          ...OrderJobDetailsStepNodeFragment
        }
      }
    }
  }
  ${OrderJobDetailsStepNodeFragment}
`
