// Role-Based Access Control Rules
const rbacRules = {
  client: {
    static: [
      'create-order:client-field',
      'order-job-details:client-role',
      'create-order:client-sos-warning',
      'navbar-user:client-details',
    ],
  },
  // driver: {
  //   static: [
  //     "home-page:driver-viewset",
  //     "driver-page:link",
  //     "driver-page:visit",
  //   ],
  //   dynamic: {
  //     "posts:edit": ({userId, postOwnerId}) => {
  //       if (!userId || !postOwnerId) return false;
  //       return userId === postOwnerId;
  //     }
  //   }
  // },
  admin: {
    static: [
      'orders-page:export',
      'orders-page:partner-filter',
      'orders-table:extra-cells',
      'settings-page:admin-link',
      'settings-page:routes',
      'create-order:admin-field',
      'create-order:admin-response',
      'order-details:comment-job',
      'order-details:delete-job',
      'order-details:complex-details',
      'order-details:admin-edit',
      'order-details:history',
      'order-job-details:change-request',
      'scheduler-page:view',
    ],
  },
}

export default rbacRules
