import React from 'react'

export default function withLayout(WrappedComponent, Layout) {
  return function HOC(props) {
    return (
      <Layout>
        <WrappedComponent {...props} />
      </Layout>
    )
  }
}
