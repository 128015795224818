import React from 'react'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { AppBar, Button, IconButton, Toolbar } from '@material-ui/core'
import {
  ExitToApp as ExitToAppIcon,
  Menu as MenuIcon,
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/styles'

import AuthContext from '../contexts/AuthContext'
import Can from '../helpers/auth'
import { urls } from '../constants/urls'
import Logo from './Logo'
import NavLink from './NavLink'

const useStyles = makeStyles((theme) => ({
  grow: { flexGrow: 1 },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'inherit',
    fontWeight: 600,
  },
  menuLinkButton: {
    marginRight: theme.spacing(2),
    color: 'inherit',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.colors.primary,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: { display: 'flex' },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: { display: 'none' },
  },
}))

const Navbar = () => {
  const classes = useStyles()
  const { user, logoutUser } = React.useContext(AuthContext)

  return (
    <AppBar color="secondary" elevation={0}>
      <Toolbar>
        <div className={classes.menuButton}>
          <Logo />
        </div>
        {user && (
          <div className={classes.sectionDesktop}>
            <Can
              role={user.role}
              perform="scheduler-page:view"
              yes={() => (
                <NavLink exact={false} to={urls.scheduler}>
                  Tervező
                </NavLink>
              )}
              no={() => null}
            />

            <NavLink to={urls.orders} exact={false}>
              Megrendelések
            </NavLink>
            <Can
              role={user.role}
              perform="settings-page:admin-link"
              yes={() => (
                <NavLink exact={false} to={urls.settingsRoot}>
                  Beállítások
                </NavLink>
              )}
              no={() => (
                <NavLink exact={false} to={urls.profileSettings}>
                  Beállítások
                </NavLink>
              )}
            />
          </div>
        )}
        <div className={classes.grow} />
        <div className={classes.sectionDesktop}>
          {user && (
            <>
              <Button
                className={classes.menuLinkButton}
                component={Link}
                to={urls.profileSettings}
              >
                <Can
                  role={user.role}
                  perform="navbar-user:client-details"
                  yes={() =>
                    `${user.name || user.email} (${user.mainPartner.name})`
                  }
                  no={() => `${user.name || user.email} (${user.role})`}
                />
              </Button>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                onClick={logoutUser}
                title="Kijelentkezés"
              >
                <ExitToAppIcon style={{ fontSize: 26 }} />
              </IconButton>
            </>
          )}
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  )
}

export default withRouter(Navbar)
