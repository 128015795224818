import gql from 'graphql-tag'

export const ADMIN_LIST = gql`
  query Admins {
    admins {
      id
      name
      email
      status
    }
  }
`
