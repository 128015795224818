import gql from 'graphql-tag'
import {
  DriverDayOffFragment,
  OrderJobDetailsFragment,
  OrderJobStepNodeFragment,
  OrderJobNodeFragment,
  MapStepNodeFragment,
} from '../fragments'

export const SCHEDULER_QUERY = gql`
  query SchedulerQuery($fromDate: Date, $toDate: Date, $skipDrivers: Boolean!) {
    unassignedOrderJobs(toDate: $toDate) {
      edges {
        node {
          ...OrderJobNodeFragment
        }
      }
    }
    drivers @skip(if: $skipDrivers) {
      id
      name
      emoji
      dayOffs(fromDate: $fromDate, toDate: $toDate) {
        edges {
          node {
            ...DayOffNodeFragment
          }
        }
      }
    }
  }
  ${DriverDayOffFragment}
  ${OrderJobNodeFragment}
`

// after succesful split OrderJobStepNode automatically updated: new step displayed on DriverTask
export const SCHEDULER_DRIVER_STEPS_QUERY = gql`
  query SchedulerDriverStepsQuery(
    $fromDate: Date
    $toDate: Date
    $status: String
  ) {
    drivers {
      id
      steps(status: $status, fromDate: $fromDate, toDate: $toDate) {
        edges {
          node {
            ...OrderJobStepNodeFragment
          }
        }
      }
    }
  }
  ${OrderJobStepNodeFragment}
`

// orderjob overlay form details
export const SCHEDULER_ORDER_JOB_DETAILS_QUERY = gql`
  query SchedulerOrderJobDetails($id: ID!) {
    orderJob(id: $id) {
      ...OrderJobDetailsFragment
    }
  }
  ${OrderJobDetailsFragment}
`

export const DRIVERS_MAP_QUERY = gql`
  query DriversMapQuery($fromDate: Date, $toDate: Date) {
    drivers {
      id
      name
      emoji
      dayOffs(fromDate: $fromDate, toDate: $fromDate) {
        edges {
          node {
            id
          }
        }
      }
      actualPosition {
        type
        coordinates
      }
      ongoingSteps: steps(
        fromDate: $fromDate
        toDate: $toDate
        status: "ONGOING"
        first: 1
      ) {
        edges {
          node {
            ...MapStepNodeFragment
          }
        }
      }
      openSteps: steps(
        fromDate: $fromDate
        toDate: $toDate
        status: "OPEN"
        first: 1
      ) {
        edges {
          node {
            ...MapStepNodeFragment
          }
        }
      }
    }
  }
  ${MapStepNodeFragment}
`
