import React from 'react'
import { Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'


import { urls } from '../constants/urls'
import NavLink from './NavLink'

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.colors.unfilled,
    backgroundColor: theme.palette.colors.bg1,
    padding: '0 15px 0 133px',
    borderTop: `1px solid ${theme.palette.colors.bg4}`,
    borderBottom: `1px solid ${theme.palette.colors.bg4}`,
  },
}))


export default function AdminSecondaryNavbar() {
  const classes = useStyles()

  return (
    <Toolbar classes={classes} variant="dense">
      <NavLink styleVariant="secondary" to={urls.partnerManagement}>
        Felhasználók menedzselése
      </NavLink>
      <NavLink styleVariant="secondary" to={urls.partnerSettings}>
        Partnerek
      </NavLink>
      {/* <NavLink styleVariant="secondary" to={urls.configurations}>
        Konfigurációk
      </NavLink> */}
      <NavLink styleVariant="secondary" to={urls.profileSettings}>
        Fiókbeállítások
      </NavLink>
    </Toolbar>
  )
}
