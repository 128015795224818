import gql from 'graphql-tag'

export const JOB_TYPE_LIST = gql`
  query JobType {
    jobTypes {
      id
      name
    }
  }
`
