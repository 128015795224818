import { createMuiTheme } from '@material-ui/core/styles'
import cityBg from '../assets/img/city_green_bg.svg'
import bigCircle from '../assets/img/big_circle_bg.svg'
import mediumCircle from '../assets/img/medium_circle_bg.svg'
import smallCircle from '../assets/img/small_circle_bg.svg'

export const colorStyles = {
  darker: '#1B1B1E',
  dark: '#34343B',
  accent: '#393B48',
  // accentDarker: '#393B48',
  primary: '#F6BD41',
  late: '#EB8600',
  appBg: '#EEEEEE',
  bg1: '#FAFAFA',
  bg2: '#EEEEEE',
  bg3: '#F5F2EC',
  bg4: '#E2E2E2',
  disabled: '#AFAFAF',
  unfilled: '#717171',
  success: '#13BA60',
  error: '#FF3030',
  black: '#05101A',
  cardStroke: '#D5E0EB',
  stepDone: '#089593',
  stepInner: '#F2F2F2',
  schedulerLine: '#E6EBED',
}

const dropShadows = {
  ds1: '0px 30px 100px rgba(35, 60, 77, 0.2)',
  ds2: '0px 14px 20px rgba(77, 73, 35, 0.06)',
  ds2_focus: '0px 14px 20px rgba(0, 0, 0, 0.1)',
  ds3: '0px 4px 5px rgba(0, 0, 0, 0.1)',
  ds4: '0px 2px 20px rgba(0, 0, 0, 0.1)',
}

const circleBackground = {
  backgroundColor: colorStyles.bg2,
  backgroundImage: `url(${bigCircle}), url(${mediumCircle}), url(${smallCircle})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition:
    'top -19px left calc(50% - 371px), top 800px right calc(50% - 267px), top 226px right calc(50% - 550.5px)',
}

const generateCityBackground = (color) => ({
  backgroundColor: color,
  backgroundImage: `radial-gradient(
      ellipse 55% 28.5%,
      ${color} 0,
      ${color} 100%,
      transparent 102%
  ), url(${cityBg})`,
  backgroundSize: '100% 240px, auto',
  backgroundRepeat: 'no-repeat, repeat-x',
  backgroundPosition: 'center 145px, left top',
})

const theme = createMuiTheme({
  props: {
    MuiButtonBase: { disableRipple: true },
    // MuiInputLabel: { shrink: true },
    MuiInput: { disableUnderline: true },
  },
  spacing: 5,
  typography: {
    fontFamily: '"Darker Grotesque", sans-serif',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 500,
  },
  palette: {
    primary: {
      dark: colorStyles.late,
      main: colorStyles.primary,
    },
    secondary: {
      dark: colorStyles.darker,
      main: colorStyles.dark,
    },
    colors: colorStyles,
    error: { main: colorStyles.error },
    success: { main: colorStyles.success },
  },
  dropShadows,
  overrides: {
    MuiAppBar: { colorDefault: { backgroundColor: '#fff' } },
    MuiBackdrop: { root: { backgroundColor: 'rgba(52, 52, 59, 0.8)' } },
    MuiButton: {
      root: {
        borderRadius: 2,
        borderColor: 'rgba(0, 0, 0, .3)',
        textTransform: 'none',
        '&:hover': { boxShadow: dropShadows.ds2 },
      },
      contained: {
        boxShadow: 'none',
        minHeight: 60,
        fontWeight: 800,
        '&$disabled': {
          backgroundColor: colorStyles.bg2,
          color: colorStyles.disabled,
          fontWeight: 600,
        },
      },
      containedSizeSmall: { minHeight: 44 },
      containedPrimary: {
        color: colorStyles.darker,
        '&:hover': { backgroundColor: colorStyles.primary },
      },
      containedSecondary: {
        color: '#FFF',
        '&:hover': { backgroundColor: colorStyles.dark },
      },
      text: {
        padding: 0,
        minWidth: 0,
        fontWeight: 'bold',
        '&:hover': {
          textDecoration: 'underline',
          backgroundColor: 'transparent',
        },
      },
      textSecondary: {
        textDecoration: 'underline',
        '&:hover': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
        '&:hover': { boxShadow: dropShadows.ds2 },
        '&$disabled': { color: 'inherit' },
      },
      primary: { '&:hover': { backgroundColor: colorStyles.primary } },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: colorStyles.accent,
          color: 'white',
          '&:hover': { backgroundColor: colorStyles.accent },
        },
      },
    },
    MuiPaper: {
      rounded: { borderRadius: 2 },
      elevation9: {
        backgroundColor: colorStyles.bg1,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: 'rgba(34, 35, 69, 0.1)',
        boxShadow: 'none',
      },
      elevation10: {
        // orders-list
        boxShadow: dropShadows.ds1,
      },
      elevation11: {
        backgroundColor: '#FFF',
        boxShadow: '0px 14px 20px rgba(35, 60, 77, 0.06)',
      },
      elevation12: {
        backgroundColor: '#FFF',
        boxShadow: '0px 14px 20px rgba(35, 60, 77, 0.1)',
      },
      // relatedJob cards, returnJob card
      elevation13: {
        backgroundColor: colorStyles.bg1,
        boxShadow: '0px 14px 20px rgba(35, 60, 77, 0.06)',
        border: `1px solid ${colorStyles.bg4}`,
      },
      // orderJob photos
      elevation14: {
        backgroundColor: 'inherit',
        boxShadow: 'none',
        border: 'none',
      },
    },
    MuiSwitch: {
      switchBase: {
        '&$checked$colorPrimary + $track': { backgroundColor: colorStyles.bg4 },
      },
      track: { backgroundColor: colorStyles.bg4 },
      checked: {},
      colorPrimary: { color: colorStyles.bg1 },
    },
    MuiStep: {
      vertical: {
        position: 'relative',
        zIndex: 2,
      },
    },
    MuiStepper: {
      vertical: {
        backgroundColor: colorStyles.darker,
        boxShadow: dropShadows.ds2,
        borderRadius: 6,
        paddingTop: 35,
        paddingLeft: 28,
        paddingRight: 0,
      },
    },
    MuiStepButton: {
      root: {
        paddingTop: 26,
        paddingRight: 0,
        paddingBottom: 26,
        paddingLeft: 13,
        boxSizing: 'border-box',
        fontSize: 16,
        fontWeight: 600,
      },
      vertical: {
        justifyContent: 'flex-start',
        lineHeight: '26px',
        margin: 0,
      },
    },
    MuiStepLabel: {
      iconContainer: {
        paddingLeft: 0,
        paddingRight: 7,
      },
      label: {
        fontSize: 16,
        lineHeight: '26px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#FFF',
        transition: 'font-size 150ms',

        '&$completed': {
          color: '#FFF',
          fontWeight: 600,
          '&$active': {
            fontWeight: 800,
            // color: colorStyles.success,
          },
        },
        '&$active': {
          fontWeight: 800,
          color: '#FFF',
          '&$error': { color: colorStyles.error },
        },
        '&$error': { color: colorStyles.error },
      },
    },
    MuiStepConnector: {
      root: { flex: 0 },
      line: { borderColor: colorStyles.dark },
      vertical: {
        marginLeft: 20,
        padding: 0,
        position: 'relative',
        zIndex: 1,
        marginTop: -15,
        marginBottom: -15,
      },
      lineVertical: {
        minHeight: 37,
        borderLeftWidth: 2,
      },
    },
    MuiTableCell: {
      root: {
        padding: '26px 12px',
        '&:first-child': { paddingLeft: 40 },
        '&:last-child': { paddingRight: 40 },
      },
      head: {
        color: colorStyles.unfilled,
        fontSize: 15,
        lineHeight: 1,
        fontWeight: 600,
      },
      body: {
        color: colorStyles.black,
        lineHeight: 1,
        fontWeight: 600,
      },
    },
    MuiTableRow: {
      root: {
        backgroundColor: '#fff',
        '&:nth-child(odd)': { backgroundColor: colorStyles.bg2 },
      },
      head: {
        backgroundColor: '#FFF',
        '&:nth-child(odd)': { backgroundColor: '#FFF' },
      },
    },
    MuiTypography: {
      root: { color: colorStyles.dark },
      h1: {
        fontSize: 30,
        fontWeight: 800,
        lineHeight: '41px',
        marginBottom: 10,
      },
      h2: {
        fontSize: 28,
        fontWeight: 800,
        lineHeight: 1,
      },
      h3: {
        fontSize: 20,
        lineHeight: 1,
        fontWeight: 800,
      },
      h4: {
        // section header
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 800,
      },
      h5: {
        fontSize: 16,
        fontWeight: 700,
        lineHeight: 1.3,
      },
      body1: {
        fontSize: 18,
        lineHeight: '24px',
        fontWeight: 600,
      },
      subtitle1: {
        fontSize: 18,
        lineHeight: '130%',
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 18,
        lineHeight: '130%',
        fontWeight: 600,
        color: colorStyles.accent,
      },
      body2: {
        fontSize: 13,
        fontWeight: 500,
        lineHeight: '130%',
      },
      colorPrimary: {
        color: colorStyles.accent,
        '&:hover': { color: colorStyles.darker },
      },
    },
  },
  circleBackground,
  cityBackgroundBg1: generateCityBackground(colorStyles.bg1),
  cityBackgroundBg2: generateCityBackground(colorStyles.bg2),
})

// console.log({ theme })

export default theme

export const bootstrapTheme = createMuiTheme({
  props: theme.props,
  spacing: theme.spacing,
  typography: theme.typography,
  palette: {
    ...theme.palette,
    primary: {
      dark: colorStyles.darker,
      main: colorStyles.dark,
    },
  },
  overrides: {
    MuiFormControl: {
      root: { position: 'relative' },
      marginNormal: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    MuiIconButton: { root: { padding: 4 } },
    MuiFormHelperText: {
      root: {
        fontWeight: 600,
        fontSize: 14,
        lineHeight: '16px',
        marginTop: 0,
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1,
        color: colorStyles.unfilled,
        '&$disabled': { color: colorStyles.disabled },
      },
      formControl: {
        transform: 'translate(18px, 40px) scale(1)',
        zIndex: 1,
      },
      shrink: { transform: 'translate(0, 0)' },
      disabled: {},
    },
    MuiFormLabel: {
      root: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: 1,
        color: colorStyles.unfilled,
        '&$disabled': { color: colorStyles.disabled },
        marginBottom: 4,
      },
    },
    MuiInput: {
      root: {
        color: colorStyles.dark,
        backgroundColor: colorStyles.bg1,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgba(34, 35, 69, 0.1)',
        borderRadius: 2,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        'label + &': { marginTop: theme.spacing(4) },
        '&$error': { borderColor: theme.palette.error.main },
        '&$focused': {
          backgroundColor: '#FFF',
          boxShadow: dropShadows.ds2,
        },
        '&:hover': { boxShadow: dropShadows.ds2 },
        '&$disabled': { backgroundColor: colorStyles.bg2 },
      },
      formControl: { 'label + &': { marginTop: theme.spacing(4) } },
      input: {
        fontSize: 17,
        lineHeight: '24px',
        fontWeight: 700,
        position: 'relative',
        zIndex: 2,
        height: 'auto',
      },
      error: {},
      disabled: {},
      focused: {},
    },
    MuiInputBase: {
      input: { padding: '16px 18px' },
      multiline: { padding: '16px 18px' },
      adornedStart: { paddingLeft: theme.spacing(2) },
      adornedEnd: { paddingRight: theme.spacing(2) },
      inputAdornedStart: { paddingLeft: 0 },
      inputAdornedEnd: { paddingRight: 0 },
    },
    MuiSelect: {
      select: {
        '&:focus, &[aria-expanded="true"]': {
          borderRadius: 2,
          backgroundColor: theme.palette.common.white,
        },
      },
      icon: {
        top: 'calc(50% - 14px)',
        zIndex: 2,
      },
    },
    MuiMenu: { paper: { marginTop: 5 } },
    MuiPaper: {
      rounded: { borderRadius: 2 },
      elevation8: { boxShadow: dropShadows.ds4 },
    },
    MuiMenuItem: {
      root: {
        fontSize: 17,
        lineHeight: 1,
        whiteSpace: 'normal',
        fontWeight: 600,
        minHeight: 46,
      },
    },
    MuiListItem: {
      button: {
        '&:hover, &:focus': { backgroundColor: colorStyles.bg2 },
        '&$selected, &$selected:hover': {
          backgroundColor: colorStyles.primary,
        },
        selected: {},
      },
    },
    MuiSvgIcon: { root: { fontSize: 24 } },
    MuiPickersDay: {
      day: {
        color: '#2C2C2C',
        borderRadius: '3px !important',
        '&:hover': { backgroundColor: theme.palette.colors.bg2 },
      },
      daySelected: {
        backgroundColor: theme.palette.colors.accent,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.colors.darker,
          color: theme.palette.common.white,
        },
      },
    },
    MuiPickersCalendarHeader: {
      transitionContainer: {
        fontWeight: 600,
        fontSize: 18,
        color: '#787878',
      },
      dayLabel: {
        color: colorStyles.dark,
        fontWeight: 800,
        fontSize: 18,
      },
      switchHeader: {
        paddingLeft: 15,
        paddingRight: 15,
        marginBottom: 15,
      },
      iconButton: {
        padding: 0,
        color: '#333',
      },
    },
    MuiPickersCalendar: {
      transitionContainer: {
        '&::before': {
          content: '""',
          display: 'block',
          height: 1,
          backgroundColor: '#CCC',
          marginLeft: 15,
          marginRight: 15,
        },
      },
    },
    MuiTypography: {
      body1: {
        fontWeight: 600,
        lineHeight: 1,
      },
      body2: { fontWeight: 600 },
    },
    MuiAutocomplete: { popup: { zIndex: 1300 } },
  },
})

export const bootstrapModifiedTheme = createMuiTheme({
  ...bootstrapTheme,
  overrides: {
    ...bootstrapTheme.overrides,
    MuiButtonBase: {
      root: { '&$focusVisible': { backgroundColor: 'rgba(0, 0, 0, 0.08)' } },
      focusVisible: {},
    },
    MuiCheckbox: {
      colorSecondary: {
        '&:hover, &$checked:hover': { backgroundColor: colorStyles.bg2 },
      },
      checked: {},
    },
  },
})

export const bootstrapSchedulerTheme = createMuiTheme({
  ...bootstrapTheme,
  props: {
    ...bootstrapTheme.props,
    MuiInputLabel: { shrink: true },
  },
  overrides: {
    ...bootstrapTheme.overrides,
    MuiInputLabel: {
      ...bootstrapTheme.overrides.MuiInputLabel,
      formControl: {
        transform: 'translate(12px, 32px) scale(1)',
        zIndex: 1,
      },
      // shrink: { transform: 'translate(0, 0)' },
    },
    MuiInputBase: {
      ...bootstrapTheme.overrides.MuiInputBase,
      input: { padding: '8px 12px' },
    },
  },
})
