import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import { paths, urls } from '../constants/urls'
import PageLoading from './PageLoading'


// Code splitting
const ProfileSettingsView = React.lazy(() => import('../views/ProfileSettingsView'))
const AdminPartnerManagementView = React.lazy(() => import('../views/AdminPartnerManagementView'))
const AdminPartnersView = React.lazy(() => import('../views/AdminPartnersView'))
// const AdminConfigurationsView = React.lazy(() => import('../views/AdminConfigurationsView'))


export default function AdminSettingsView() {
  return (
    <React.Suspense fallback={<PageLoading />}>
      <Switch>
        <Route exact path={paths.partnerManagement} component={AdminPartnerManagementView} />
        <Route exact path={paths.partnerSettings} component={AdminPartnersView} />
        {/* <Route exact path={paths.configurations} component={AdminConfigurationsView} /> */}
        <Route exact path={paths.profileSettings} component={ProfileSettingsView} />

        <Redirect from={urls.settingsRoot} to={urls.partnerManagement} />
      </Switch>
    </React.Suspense>
  )
}
