import React from 'react'

export default function Logo() {
  return (
    <svg width="106" height="29" viewBox="0 0 106 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M91.5201 12.5434C87.9323 12.0935 81.8644 10.2779 75.8747 8.28942C68.5628 5.86294 60.2744 3.11197 54.2396 2.6121C46.5234 1.97159 38.8147 5.88835 33.714 8.48004C31.5846 9.56196 29.9031 10.416 28.6813 10.7134C27.1002 11.098 24.7206 11.2098 21.9646 11.3386C17.7493 11.536 12.502 11.6123 8.62218 12.9662C2.70492 15.0309 0.0519357 20.4574 0.0340562 20.5048L0 20.4913V17.3921C1.36736 15.457 3.78876 11.9588 7.77759 10.5668C12.0091 9.0909 17.4623 9.00448 21.8445 8.79945C24.4685 8.6766 26.7341 8.5707 28.0751 8.24451C29.0065 8.01745 30.6497 7.18293 32.5526 6.21623C37.9114 3.49322 46.0083 -0.620948 54.4525 0.0788678C60.7921 0.605 69.2346 3.40681 76.6835 5.87903C82.6059 7.84462 86.582 9.26628 90.644 9.82291C92.4626 10.072 93.5354 10.1635 94.34 10.2186C94.34 10.2186 94.3587 10.2203 94.3945 10.2228C94.4047 10.2237 94.4158 10.2245 94.4285 10.2253C94.5707 10.2364 94.8764 10.2584 95.2936 10.2804C95.3693 10.2847 95.4494 10.2889 95.532 10.2931C97.8921 10.4253 98.6856 10.3618 98.9078 10.3304C98.9172 10.3304 98.9257 10.3304 98.935 10.3296C99.4212 10.3135 99.9099 10.2855 100.381 10.2414C100.381 10.2414 101.743 13.122 106 15.2401L91.5201 12.5434Z" fill="#F6BD41" />
      <path d="M45.04 5.15796L36.1854 10.2414L70.497 10.4108L42.2303 8.46217L45.04 5.15796Z" fill="#F6BD41" />
      <path d="M23.1196 25.4065H21.1685V14.351H28.9924V16.0791H23.1196V18.7889H27.6071V20.4188H23.1196V25.4065Z" fill="white" />
      <path d="M31.171 16.4522C30.7417 16.4653 30.4555 16.3671 30.3125 16.1576C30.1824 15.9351 30.1174 15.6732 30.1174 15.3721C30.1174 15.0841 30.1889 14.8354 30.332 14.626C30.4751 14.4165 30.7547 14.3118 31.171 14.3118C31.5742 14.3118 31.8408 14.4165 31.9709 14.626C32.114 14.8354 32.1855 15.0841 32.1855 15.3721C32.1855 15.6732 32.114 15.9285 31.9709 16.138C31.8408 16.3343 31.5742 16.4391 31.171 16.4522ZM30.1564 25.4065V17.3358H32.1465V25.4065H30.1564Z" fill="white" />
      <path d="M36.6129 25.4065C35.9755 25.4065 35.4942 25.2494 35.1691 24.9352C34.8569 24.621 34.7008 24.1105 34.7008 23.4036V18.7497H33.4911V17.3358C33.8683 17.3358 34.161 17.2965 34.3691 17.218C34.5772 17.1394 34.7268 16.9562 34.8179 16.6682C34.9089 16.3802 34.9544 15.9154 34.9544 15.274H36.5543V17.3358H38.3884V18.7497H36.5543V23.8356H38.4274V25.4065H36.6129Z" fill="white" />
      <path d="M42.5577 25.4065C41.9203 25.4065 41.439 25.2494 41.1138 24.9352C40.8017 24.621 40.6456 24.1105 40.6456 23.4036V18.7497H39.4359V17.3358C39.8131 17.3358 40.1058 17.2965 40.3139 17.218C40.522 17.1394 40.6716 16.9562 40.7626 16.6682C40.8537 16.3802 40.8992 15.9154 40.8992 15.274H42.4991V17.3358H44.3332V18.7497H42.4991V23.8356H44.3722V25.4065H42.5577Z" fill="white" />
      <path d="M47.1952 16.4522C46.766 16.4653 46.4798 16.3671 46.3367 16.1576C46.2067 15.9351 46.1416 15.6732 46.1416 15.3721C46.1416 15.0841 46.2132 14.8354 46.3562 14.626C46.4993 14.4165 46.779 14.3118 47.1952 14.3118C47.5984 14.3118 47.8651 14.4165 47.9952 14.626C48.1383 14.8354 48.2098 15.0841 48.2098 15.3721C48.2098 15.6732 48.1383 15.9285 47.9952 16.138C47.8651 16.3343 47.5984 16.4391 47.1952 16.4522ZM46.1806 25.4065V17.3358H48.1708V25.4065H46.1806Z" fill="white" />
      <path d="M54.8832 25.6029C54.3889 25.6029 53.9727 25.5309 53.6345 25.3869C53.3093 25.2298 53.0427 25.0465 52.8345 24.837C52.6394 24.6276 52.4898 24.4377 52.3858 24.2676C52.2947 24.0974 52.2427 23.9992 52.2297 23.973H52.1907V29H50.142V17.3358H51.8785L52.2102 19.1031H52.2492C52.2622 19.0638 52.3077 18.9526 52.3858 18.7693C52.4768 18.586 52.6134 18.3831 52.7955 18.1606C52.9906 17.938 53.2573 17.7482 53.5955 17.5911C53.9337 17.4209 54.3564 17.3358 54.8637 17.3358C56.0604 17.3358 56.9189 17.722 57.4392 18.4944C57.9595 19.2668 58.2196 20.2748 58.2196 21.5184C58.2196 22.7621 57.953 23.757 57.4197 24.5032C56.8864 25.2363 56.0409 25.6029 54.8832 25.6029ZM54.2784 23.9141C54.8247 23.9141 55.2409 23.8028 55.5271 23.5803C55.8262 23.3446 56.0279 23.0435 56.1319 22.677C56.249 22.2974 56.3075 21.9046 56.3075 21.4988C56.3075 21.093 56.2425 20.7133 56.1124 20.3599C55.9953 19.9933 55.7872 19.6988 55.4881 19.4762C55.1889 19.2406 54.7726 19.1228 54.2393 19.1228C53.5109 19.1228 52.9906 19.3518 52.6785 19.81C52.3663 20.2551 52.2102 20.8181 52.2102 21.4988C52.2102 22.2057 52.3728 22.7883 52.698 23.2465C53.0232 23.6916 53.5499 23.9141 54.2784 23.9141Z" fill="white" />
      <path d="M62.2621 25.6029C61.1564 25.6029 60.3695 25.2232 59.9012 24.4639C59.433 23.7046 59.1988 22.7097 59.1988 21.4792C59.1988 20.2355 59.433 19.234 59.9012 18.4747C60.3825 17.7024 61.1694 17.3162 62.2621 17.3162C62.8864 17.3162 63.3807 17.4275 63.7449 17.65C64.1091 17.8595 64.3823 18.0951 64.5644 18.3569C64.7465 18.6187 64.857 18.8151 64.8961 18.946H64.9351V17.3358H66.9838V25.4065H65.2668L64.9351 24.0908H64.8961C64.857 24.2087 64.74 24.3919 64.5449 24.6407C64.3628 24.8763 64.0831 25.0989 63.7059 25.3083C63.3417 25.5047 62.8604 25.6029 62.2621 25.6029ZM63.0815 23.9141C63.81 23.9141 64.2977 23.685 64.5449 23.2268C64.805 22.7555 64.9351 22.1795 64.9351 21.4988C64.9351 20.7788 64.805 20.1962 64.5449 19.7511C64.2847 19.306 63.7969 19.0835 63.0815 19.0835C62.5742 19.0835 62.1775 19.1948 61.8914 19.4173C61.6182 19.6399 61.4231 19.9344 61.306 20.301C61.202 20.6544 61.1499 21.0537 61.1499 21.4988C61.1499 21.9439 61.202 22.3497 61.306 22.7163C61.4231 23.0828 61.6182 23.3774 61.8914 23.5999C62.1775 23.8094 62.5742 23.9141 63.0815 23.9141Z" fill="white" />
      <path d="M68.5666 25.4065V14.351H70.5177V25.4065H68.5666Z" fill="white" />
      <path d="M75.5962 25.6029C74.3996 25.6029 73.5476 25.2298 73.0403 24.4836C72.533 23.7243 72.2794 22.7359 72.2794 21.5184C72.2794 20.2486 72.5395 19.234 73.0598 18.4747C73.5801 17.7155 74.4256 17.3358 75.5962 17.3358C76.1035 17.3358 76.5263 17.4209 76.8645 17.5911C77.2157 17.7482 77.4888 17.938 77.6839 18.1606C77.892 18.3831 78.0416 18.586 78.1327 18.7693C78.2237 18.9526 78.2758 19.0638 78.2888 19.1031H78.3278V14.351H80.3765V25.4065H78.6595L78.3278 23.973H78.2888C78.2758 23.9992 78.2172 24.0974 78.1132 24.2676C78.0221 24.4377 77.866 24.6276 77.6449 24.837C77.4368 25.0465 77.1636 25.2298 76.8254 25.3869C76.4872 25.5309 76.0775 25.6029 75.5962 25.6029ZM76.2401 23.9141C76.9815 23.9141 77.5083 23.6916 77.8205 23.2465C78.1457 22.7883 78.3083 22.2057 78.3083 21.4988C78.3083 20.8181 78.1522 20.2551 77.84 19.81C77.5408 19.3518 77.0271 19.1228 76.2986 19.1228C75.7653 19.1228 75.3426 19.2406 75.0304 19.4762C74.7312 19.6988 74.5231 19.9933 74.4061 20.3599C74.289 20.7133 74.2305 21.093 74.2305 21.4988C74.2305 21.9046 74.2825 22.2974 74.3865 22.677C74.5036 23.0435 74.7052 23.3446 74.9914 23.5803C75.2906 23.8028 75.7068 23.9141 76.2401 23.9141Z" fill="white" />
      <path d="M83.3594 16.4522C82.9301 16.4653 82.644 16.3671 82.5009 16.1576C82.3708 15.9351 82.3058 15.6732 82.3058 15.3721C82.3058 15.0841 82.3773 14.8354 82.5204 14.626C82.6635 14.4165 82.9431 14.3118 83.3594 14.3118C83.7626 14.3118 84.0293 14.4165 84.1593 14.626C84.3024 14.8354 84.374 15.0841 84.374 15.3721C84.374 15.6732 84.3024 15.9285 84.1593 16.138C84.0293 16.3343 83.7626 16.4391 83.3594 16.4522ZM82.3448 25.4065V17.3358H84.3349V25.4065H82.3448Z" fill="white" />
    </svg>
  )
}
