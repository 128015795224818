import Cookies from 'js-cookie'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { TokenRefreshLink } from 'apollo-link-token-refresh'
import { createHttpLink } from 'apollo-link-http'
// import { onError } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { print } from 'graphql/language/printer'

// assets
import { GRAPHQL_URI } from '../constants/config'
import * as authUtils from './auth'
import { isTokenExpired } from './jwt-token'
import { REFRESH_TOKEN_MUTATION } from '../graphql/mutations'
import introspectionQueryResultData from '../graphql/fragmentTypes.json'

const initialState = { isLoggedIn: authUtils.hasToken() }

function callGraphQLQuery(query, variables, extraHeaders) {
  return fetch(GRAPHQL_URI, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      ...extraHeaders,
    },
    body: JSON.stringify({
      query: print(query),
      variables,
    }),
  }).then((response) => response.json())
}

/* eslint-disable */
// NOTE: https://www.apollographql.com/docs/react/v2/data/fragments/#fragments-on-unions-and-interfaces
// need to use custom fragmentMatcher cause of usage of fragments on unions and interfaces (orderJobAnalytics)
/* eslint-enable */
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
})

export default function createApolloClient(appVersion) {
  // NOTE: using relative path to avoid dev / stage / prod different hostnames
  const httpLink = createHttpLink({
    uri: GRAPHQL_URI, // on localhost CRA proxy will handle port forwarding from 3000 to 18060
    credentials: 'same-origin',
  })

  const tokenRefreshLink = new TokenRefreshLink({
    accessTokenField: 'refreshToken', // mutation function name
    isTokenValidOrUndefined: () => !isTokenExpired() || !authUtils.hasToken(),
    fetchAccessToken: () => {
      const refreshToken = authUtils.getRefreshToken()
      const csrftoken = Cookies.get('csrftoken')

      return callGraphQLQuery(
        REFRESH_TOKEN_MUTATION,
        { refreshToken },
        {
          'X-CSRFToken': csrftoken,
          APP_VERSION: appVersion,
        },
      )
    },
    handleFetch: (response) => {
      // console.log('new access token', response)
      authUtils.setToken(response)
    },
    handleResponse: (operation) => (response) => {
      // console.log('handle response', { operation, accessTokenField, response })
      if (response.errors) {
        authUtils.logoutUser(operation.getContext())
      }

      return response
    },
    handleError: (err) => {
      console.log(err.message)
    },
  })

  // add Authorization header to every server request
  // setContext((request, previousContext) => { ... })
  const authLink = setContext((_, { headers = {} }) => {
    const token = authUtils.getAccessToken()
    const csrftoken = Cookies.get('csrftoken')

    return {
      headers: {
        ...headers,
        Authorization: token ? `JWT ${token}` : '',
        'X-CSRFToken': csrftoken,
        'Accept-Language': 'hu',
        APP_VERSION: appVersion,
      },
    }
  })
  // create link to handle request errors
  // const errorLink = onError(({ graphQLErrors, networkError, operation, response }) => {
  //
  //   console.log('[onError afterware]', { operation, response })
  //
  //   if (graphQLErrors) {
  //     // const unAuthenticated = graphQLErrors.some(({ extensions }) => extensions.code === 'UNAUTHENTICATED')
  //     // if(unAuthenticated) {
  //     //   // logout logic
  //     //   clearToken() // clear token from localStorage
  //     //   writeAuthQuery(operation.getContext(), false) // notify react app
  //     //   response.errors = null // ignoring UNAUTHENTICATED errors
  //     // }
  //     console.log('[GraphQL Errors]', graphQLErrors)
  //   }
  //   if (networkError) console.log('[Network Errors]', networkError)
  // })

  const link = ApolloLink.from([
    tokenRefreshLink,
    authLink,
    // errorLink,
    httpLink,
  ])

  const cache = new InMemoryCache({ fragmentMatcher })
  // initialize cache
  cache.writeData({ data: initialState })

  return new ApolloClient({
    link,
    cache,
  })
}
