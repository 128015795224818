import gql from 'graphql-tag'

export const CLIENT_LIST = gql`
  query Client {
    clients {
      id
      name
      email
      phoneNumber
      mainPartner {
        id
        name
      }
    }
  }
`
