import gql from 'graphql-tag'

export const CLIENT_CREATE = gql`
  mutation CreateClient(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $mainPartnerId: ID!
  ) {
    createClient(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      mainPartnerId: $mainPartnerId
    ) {
      client {
        id
        name
        email
        phoneNumber
        mainPartner {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CLIENT_UPDATE = gql`
  mutation UpdateClient(
    $id: ID!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $mainPartnerId: ID!
  ) {
    updateClient(
      id: $id
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      mainPartnerId: $mainPartnerId
    ) {
      client {
        id
        name
        email
        phoneNumber
        mainPartner {
          id
          name
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CLIENT_DELETE = gql`
  mutation DeleteClient($id: ID!) {
    deleteClient(clientId: $id) {
      success
    }
  }
`
