import gql from 'graphql-tag'

export const DriverDayOffFragment = gql`
  fragment DayOffNodeFragment on DayOffNode {
    id
    driver {
      id
      driverId
    }
  }
`
