import gql from 'graphql-tag'
import { DriverDayOffFragment } from '../fragments'

export const DRIVER_CREATE = gql`
  mutation DriverPartner(
    $name: String!
    $email: String!
    $phoneNumber: String!
    $emoji: String!
  ) {
    createDriver(
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      emoji: $emoji
    ) {
      driver {
        id
        name
        email
        emoji
        phoneNumber
      }
      errors {
        field
        messages
      }
    }
  }
`

export const DRIVER_DELETE = gql`
  mutation DeleteDriver($id: ID!) {
    deleteDriver(id: $id) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const DRIVER_UPDATE = gql`
  mutation UpdateDriver(
    $id: ID!
    $name: String!
    $email: String!
    $phoneNumber: String!
    $emoji: String!
  ) {
    updateDriver(
      id: $id
      name: $name
      email: $email
      phoneNumber: $phoneNumber
      emoji: $emoji
    ) {
      driver {
        id
        name
        email
        emoji
        phoneNumber
      }
      errors {
        field
        messages
      }
    }
  }
`

export const CREATE_DRIVER_DAY_OFF = gql`
  mutation CreateDriverDayOff($data: CreateDayOffInputType!) {
    createDayOff(data: $data) {
      dayOff {
        ...DayOffNodeFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${DriverDayOffFragment}
`

export const DELETE_DRIVER_DAY_OFF = gql`
  mutation DeleteDriverDayOff($data: DeleteDayOffInputType!) {
    deleteDayOff(data: $data) {
      success
      errors {
        field
        messages
      }
    }
  }
`
