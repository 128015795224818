import { array, bool, func, object, oneOf, oneOfType, string } from 'prop-types'
import rbacRules from '../constants/rbacRules'


export function checkPermission(rules, role, action, data) {
  const permissions = rules[role]
  if (!permissions) {
    // role is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule provided for action
    return true
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false
    }

    return permissionCondition(data)
  }
  return false
}


export default function Can(props) {
  return checkPermission(rbacRules, props.role, props.perform, props.data) ? props.yes() : props.no()
}

Can.defaultProps = {
  yes: () => null,
  no: () => null,
}

Can.propTypes = {
  yes: func,
  no: func,
  role: oneOf(['client', 'driver', 'admin']).isRequired,
  perform: string.isRequired,
  data: oneOfType([
    string,
    bool,
    object,
    array,
  ]),
}

export function hasPermission(props) {
  return checkPermission(rbacRules, props.role, props.perform, props.data)
}
