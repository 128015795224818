import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import AdminSettingsRoutes from './AdminSettingsRoutes'
import UserSettingsRoutes from './UserSettingsRoutes'
import AuthContext from '../contexts/AuthContext'
import { paths, urls } from '../constants/urls'
import PageLoading from './PageLoading'
import AdminSettingsLayout from '../layouts/AdminSettingsLayout'
import Can from '../helpers/auth'
import withLayout from '../helpers/layout'

// Code splitting
const OrdersListView = React.lazy(() => import('../views/OrdersListView'))
const OrderJobDetailsView = React.lazy(() =>
  import('../views/OrderJobDetailsView'),
)
const SchedulerView = React.lazy(() => import('../views/SchedulerView'))

function AppProtectedRoutes() {
  const { user } = React.useContext(AuthContext)

  return (
    <React.Suspense fallback={<PageLoading />}>
      <Switch>
        <Route
          path={paths.settingsRoot}
          render={(props) => {
            if (!user) {
              return null
            }

            return (
              <Can
                role={user.role}
                perform="settings-page:routes"
                yes={withLayout(
                  () => (
                    <AdminSettingsRoutes {...props} />
                  ),
                  AdminSettingsLayout,
                )}
                no={() => <UserSettingsRoutes {...props} />}
              />
            )
          }}
        />

        <Route
          path={paths.scheduler}
          render={(props) => {
            if (!user) {
              return null
            }

            return (
              <Can
                role={user.role}
                perform="scheduler-page:view"
                yes={() => <SchedulerView />}
                no={() => <Redirect to={urls.orders} />}
              />
            )
          }}
        />

        <Route
          exact
          path={paths.orderJobDetails}
          component={OrderJobDetailsView}
        />

        <Route exact path={paths.orders} component={OrdersListView} />

        <Redirect from={urls.root} to={urls.orders} />
      </Switch>
    </React.Suspense>
  )
}

export default AppProtectedRoutes
