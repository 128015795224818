import gql from 'graphql-tag'

export const MAIN_PARTNER_LIST = gql`
  query MainPartners {
    mainPartners {
      id
      name
      shortName
      partnerType {
        id
        name
      }
      allowedJobTypes {
        id
        name
      }
      sites {
        id
        name
        shortName
        placeId
        # location {
        #   coordinates
        #   type
        # }
      }
    }
  }
`

export const PARTNER_TYPE_LIST = gql`
  query PartnerType {
    partnerTypes {
      id
      name
    }
  }
`

export const PARTNER_MEMBERS_LIST = gql`
  query PartnerMembers {
    partnerMembers {
      id
      name
    }
  }
`

export const PARTNERS_QUERY = gql`
  query Partners($mainPartnerId: ID!) {
    partners(mainPartnerId: $mainPartnerId) {
      id
      name
    }
  }
`
