import React from 'react'

import PageLoading from './PageLoading'
import PublicLayout from '../layouts/PublicLayout'
import withLayout from '../helpers/layout'

// Code splitting
const LoginView = React.lazy(() => import('../views/LoginView'))

export default function LoginPage() {

  const Page = withLayout(LoginView, PublicLayout)

  return (
    <React.Suspense fallback={<PageLoading />}>
      <Page />
    </React.Suspense>
  )
}
