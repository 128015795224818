import React from 'react'
import { bool } from 'prop-types'
import { Helmet } from 'react-helmet'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Link from '@material-ui/core/Link'
import { AdapterLink } from '../components/NavLink'
import AuthContext from '../contexts/AuthContext'
import Can from '../helpers/auth'
import { urls } from '../constants/urls'

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 140,
    lineHeight: 1,
    marginBottom: 50,
  },
  content: {
    fontSize: 32,
    lineHeight: 1,
    fontWeight: 800,
  },
  error: {
    marginTop: 40,
    fontSize: 20,
    lineHeight: 1.2,
  },
  menuTitle: {
    marginTop: 120,
    marginBottom: 10,
    fontWeight: 800,
    fontSize: 18,
    lineHeight: 1.2,
    display: 'inline-block',
    borderBottomWidth: 1,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.colors.primary,
  },
  menu: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
}))

const MenuLink = (props) => (
  <li>
    <Link component={AdapterLink} color="primary" underline="hover" {...props} />
  </li>
)


export default function NoPageView({ isLoggedIn }) {
  const { user } = React.useContext(AuthContext)
  const classes = useStyles()
  return (
    <div>
      <Helmet title="404 | Fittipaldi App" />

      <Typography variant="h1" className={classes.title}>Hoppá!</Typography>

      <Typography variant="body1" className={classes.content}>
        Úgy tűnik a keresett oldal nem található.
        <br />
        Kérjük ellenőrizd a beírt URL-t
      </Typography>

      <Typography variant="body1" className={classes.error}>Hiba kód: 404</Typography>

      <Typography className={classes.menuTitle}>Elérhető oldalak:</Typography>
      <ul className={classes.menu}>
        {!isLoggedIn && (
          <>
            <MenuLink to={urls.login}>Bejelentkezés</MenuLink>
            <MenuLink to={urls.resetPassword}>Elfelejtett jelszó</MenuLink>
            <MenuLink to={urls.profileSettings}>Beállítások</MenuLink>
          </>
        )}
        <MenuLink to={urls.orders}>Megrendelések</MenuLink>
        {isLoggedIn && user && (
          <Can
            role={user.role}
            perform="settings-page:admin-link"
            yes={() => (
              <>
                <MenuLink to={urls.partnerManagement}>Felhasználók menedzselése</MenuLink>
                <MenuLink to={urls.partnerSettings}>Partnerek</MenuLink>
                <MenuLink to={urls.profileSettings}>Fiókbeállítások</MenuLink>
              </>
            )}
            no={() => (
              <MenuLink to={urls.profileSettings}>Beállítások</MenuLink>
            )}
          />
        )}
      </ul>
    </div>
  )
}

NoPageView.propTypes = { isLoggedIn: bool.isRequired }
