function createRoute(name, url, path, isPrivate = false) {
  return {
    name,
    path,
    url,
    isPrivate,
  }
}
function publicRoute(name, url, path) {
  const routePath = path || url
  return createRoute(name, url, routePath)
}
function privateRoute(name, url, path) {
  const routePath = path || url
  return createRoute(name, url, routePath, true)
}
const reduceToObject = (key) => (acc, curr) => {
  acc[curr.name] = curr[key]
  return acc
}

const settingsRoot = '/settings/'
const appRoutes = [
  publicRoute('login', '/login/'),
  publicRoute('resetPassword', '/reset-password/'),
  publicRoute(
    'confirmPassword',
    '/reset-password-confirm/',
    '/reset-password-confirm/:uid/:token/',
  ),
  publicRoute(
    'acceptInvitation',
    '/accept-invitation/',
    '/accept-invitation/:uid/:token/',
  ),
  privateRoute('root', '/'),
  privateRoute('settingsRoot', settingsRoot),
  privateRoute('partnerManagement', `${settingsRoot}management/`),
  privateRoute('partnerSettings', `${settingsRoot}partners/`),
  privateRoute('configurations', `${settingsRoot}configurations/`),
  privateRoute('profileSettings', `${settingsRoot}profile/`),
  privateRoute('orders', '/orders/'),
  privateRoute('orderJobDetails', '/orders/:jobid/'),
  privateRoute('scheduler', '/scheduler/'),
]

export const urls = appRoutes.reduce(reduceToObject('url'), {})
export const paths = appRoutes.reduce(reduceToObject('path'), {})
export const publicPaths = appRoutes
  .filter((o) => !o.isPrivate)
  .map((r) => r.path)
export const privatePaths = appRoutes
  .filter((o) => o.isPrivate)
  .map((r) => r.path)
