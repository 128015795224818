import gql from 'graphql-tag'

export const ME_QUERY = gql`
  query MeQuery {
    me {
      id
      email
      name
      role
      mainPartner {
        name
      }
      partnerUser {
        id
      }
    }
  }
`
