import gql from 'graphql-tag'


// NOTE https://medium.com/@kamenminkov.com/authentication-flow-with-apollo-and-react-bfc5b86d19c2
// cache is set in errorLink
export const AUTH_QUERY = gql`
  query AuthQuery {
    auth @client {
      isLoggedIn
    }
  }
`
