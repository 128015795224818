import gql from 'graphql-tag'
import {
  OrderJobUpdateStepsFragment,
  OrderJobDetailsFragment,
} from '../fragments'

export const SPLIT_TRAVEL_STEP_MUTATION = gql`
  mutation SplitTravelStepMutation($data: SplitTravelStepInputType!) {
    splitTravelStep(data: $data) {
      orderJob {
        ...OrderJobUpdateStepsFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OrderJobUpdateStepsFragment}
`
export const UNDO_SPLIT_TRAVEL_STEP_MUTATION = gql`
  mutation UndoSplitTravelStepMutation($data: UndoSplitTravelStepInputType!) {
    undoSplitTravelStep(data: $data) {
      orderJob {
        ...OrderJobUpdateStepsFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OrderJobUpdateStepsFragment}
`

export const BULK_STEP_UPDATE_MUTATION = gql`
  mutation BulkStepUpdateMutation($data: BulkStepUpdateInputType!) {
    bulkStepUpdate(data: $data) {
      orderJob {
        ...OrderJobUpdateStepsFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OrderJobUpdateStepsFragment}
`

export const TERMINATE_ORDER_JOB_MUTATION = gql`
  mutation TerminateOrderJobMutation($data: TerminateOrderJobInputType!) {
    terminateOrderJob(data: $data) {
      orderJob {
        ...OrderJobDetailsFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OrderJobDetailsFragment}
`

export const COMPLETE_ORDER_JOB_MUTATION = gql`
  mutation CompleteOrderJobMutation($data: CompleteOrderJobInputType!) {
    completeOrderJob(data: $data) {
      orderJob {
        ...OrderJobDetailsFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OrderJobDetailsFragment}
`

export const REMOVE_DRIVERS_FROM_JOB_MUTATION = gql`
  mutation removeDriversFromJobMutation($data: RemoveDriversFromJobInputType!) {
    removeDriversFromJob(data: $data) {
      orderJob {
        ...OrderJobUpdateStepsFragment
      }
      errors {
        field
        messages
      }
    }
  }
  ${OrderJobUpdateStepsFragment}
`
