import gql from 'graphql-tag'

export const MAIN_PARTNER_CREATE = gql`
  mutation CreateMainPartner(
      $name: String!,
      $shortName: String!,
      $partnerTypeId: Int!,
      $allowedJobTypes: [ID!]!,
      $sites: [SiteInputType]!,
  ) {
    createMainPartner(
      name: $name
      shortName: $shortName
      partnerTypeId: $partnerTypeId
      allowedJobTypes: $allowedJobTypes
      sites: $sites
    ) {
      mainPartner {
        id
        name
        shortName
        partnerType {
          id
          name
        }
        allowedJobTypes {
          id
          name
        }
        sites {
          id
          name
          shortName
          placeId
          # location {
          #   coordinates
          #   type
          # }
        }
      }
    }
  }
`

export const MAIN_PARTNER_DELETE = gql`
  mutation DeleteMainPartner($id: ID!) {
    deleteMainPartner(id: $id) {
      success
    }
  }
`

export const MAIN_PARTNER_UPDATE = gql`
  mutation UpdateMainPartner(
    $id: ID!,
    $name: String!,
    $shortName: String!,
    $partnerTypeId: ID!,
    $allowedJobTypes: [ID!]!,
    $sites: [SiteInputType]!,
  ) {
    updateMainPartner(
      id: $id
      name: $name
      shortName: $shortName
      partnerTypeId: $partnerTypeId
      allowedJobTypes: $allowedJobTypes
      sites: $sites
    ) {
      mainPartner {
        id
        name
        shortName
        partnerType {
          id
        }
        allowedJobTypes {
          id
          name
        }
        sites {
          id
          name
          shortName
          placeId
          # location {
          #   coordinates
          #   type
          # }
        }
      }
    }
  }
`
