import React from 'react'
import { node } from 'prop-types'
import { Toolbar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ModalRoot } from '../modals/controls'
import AuthContext from '../contexts/AuthContext'
import Navbar from '../components/Navbar'
import LoginPage from '../components/LoginPage'


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    ...theme.circleBackground,
  },
  authentication: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

export default function PrivateLayout({ children }) {
  const classes = useStyles()
  const { user, isLoggedIn } = React.useContext(AuthContext)

  if (!isLoggedIn) {
    return <LoginPage />
  }

  return (
    <>
      <ModalRoot />
      <Navbar />

      <div className={classes.root}>
        <Toolbar />
        {user ? children : (
          <div className={classes.authentication}>
            <Typography align="center">Felhasználó azonosítása folyamatban...</Typography>
          </div>
        )}
      </div>
    </>
  )
}

PrivateLayout.propTypes = { children: node.isRequired }
