import React from 'react'
import { node } from 'prop-types'
import Container from '@material-ui/core/Container'

import AdminSecondaryNavbar from '../components/AdminSecondaryNavbar'


export default function AdminSettingsLayout({ children }) {
  return (
    <>
      <AdminSecondaryNavbar />

      <Container maxWidth={false}>
        {children}
      </Container>
    </>
  )
}

AdminSettingsLayout.propTypes = { children: node.isRequired }
