import gql from 'graphql-tag'

import { OrderJobNodeFragment } from './scheduler'

export const MapStepNodeFragment = gql`
  fragment MapStepNodeFragment on OrderJobStepNode {
    id
    address {
      location {
        type
        coordinates
      }
    }
    orderJob {
      ...OrderJobNodeFragment
    }
  }
  ${OrderJobNodeFragment}
`
