import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation CreateToken($email: String!, $password: String!) {
    createToken(email: $email, password: $password) {
      token
      refreshToken
    }
  }
`

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`

export const LOGOUT_MUTATION = gql`
  mutation RevokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
    }
  }
`

export const RESET_PASSWORD_MUTATION = gql`
  mutation ResetPasswordMutation($email: String!) {
    resetPassword(email: $email) {
      success
    }
  }
`

export const CONFIRM_PASSWORD_MUTATION = gql`
  mutation ResetPasswordConfirmMutation($uid: String!, $token: String!, $newPassword: String!, $reNewPassword: String!) {
    resetPasswordConfirm(uid: $uid, token: $token, newPassword: $newPassword, reNewPassword: $reNewPassword) {
      success
      errors {
        field
        messages
      }
    }
  }
`

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation($newPassword: String!, $reNewPassword: String!, $token: String!, $uid: String!) {
    acceptInvitation(newPassword: $newPassword, reNewPassword: $reNewPassword, token: $token, uid: $uid) {
      success
      errors {
        field
        messages
      }
      token
      refreshToken
    }
  }
`

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePasswordMutation($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
    changePassword(oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors {
        field
        messages
      }
    }
  }
`
