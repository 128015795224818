import React from 'react'
import { node } from 'prop-types'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

import Logo from '../components/Logo'
import publicBGSrc from '../assets/img/public_bg.png'
import carBGSrc from '../assets/img/car_bg.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    // alignItems: 'center',
    // flexDirection: 'column',
    backgroundColor: theme.palette.colors.appBg,
    backgroundImage: `url(${publicBGSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top left',
    backgroundSize: 'cover',
    overflow: 'auto',
  },
  firstColumn: {
    width: 130,
    backgroundColor: theme.palette.colors.darker,
  },
  logoContainer: {
    width: 130,
    height: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.colors.dark,
  },
  secondColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 448,
    minWidth: 448,
    paddingLeft: 65,
    paddingRight: 65,
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 14px 20px rgba(77, 73, 35, 0.06)',
  },
  thirdColumn: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // képek
  },
  contentBox: {
    minHeight: 620,
    marginTop: 40,
    marginBottom: 40,
  },
  carBox: {
    width: 670,
    height: 620,
    margin: 45,
    position: 'relative',
    backgroundImage: `url(${carBGSrc})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  carBoxTitle: {
    position: 'absolute',
    top: -22,
    right: 20,
    height: 56,
    display: 'flex',
    alignItems: 'center',
    '&::after': {
      position: 'absolute',
      top: 0,
      right: -60,
      display: 'block',
      content: '""',
      width: 56,
      height: 56,
      borderRadius: '50%',
      backgroundColor: theme.palette.colors.darker,
    },
  },
}))

export default function PublicLayout({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.firstColumn}>
        <div className={classes.logoContainer}>
          <Logo />
        </div>
      </div>
      <div className={classes.secondColumn}>
        <div className={classes.contentBox}>
          {children}
        </div>
      </div>
      <div className={classes.thirdColumn}>
        <div className={classes.carBox}>
          <div className={classes.carBoxTitle}>
            <Typography>¨Mi vezetünk.¨</Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

PublicLayout.propTypes = { children: node.isRequired }
