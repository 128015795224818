import React from 'react'
import ReactDOM from 'react-dom'
import 'isomorphic-fetch'
import { ApolloProvider } from 'react-apollo'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import huLocale from 'date-fns/locale/hu'

import App from './components/App'
import { unregister as unregisterServiceWorker } from './serviceWorker'
import createApolloClient from './utils/apollo-client'
import './assets/css/styles.css'

// import schemaGen from './graphqlFragmentTypesGenerator'

const { appVersion } = window

function getCSRFToken() {
  // eslint-disable-next-line
  // NOTE: call server to get valid csrftoken / set cookie via header, no content in response
  return fetch('/csrf/', {
    credentials: 'same-origin',
    headers: { APP_VERSION: appVersion },
  })
}

async function initApp() {
  // set valid csrftoken cookie
  await getCSRFToken()
  const client = createApolloClient(appVersion)

  ReactDOM.render(
    <ApolloProvider client={client}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={huLocale}>
        <App />
      </MuiPickersUtilsProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  )
}

// NOTE call this function and paste results into ./graphql/fragmentTypes.json
// async function generateGraphqlSchema() {
//   await getCSRFToken()
//   await schemaGen()
// }
// generateGraphqlSchema()

initApp()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregisterServiceWorker()
