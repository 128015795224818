import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useApolloClient } from '@apollo/react-hooks'
import { SnackbarProvider } from 'notistack'

import AuthContext from '../contexts/AuthContext'
import { ModalProvider } from '../modals/controls'
import ProtectedRoute from './ProtectedRoute'
import AppPublicRoutes from './AppPublicRoutes'
import AppProtectedRoutes from './AppProtectedRoutes'
import PublicLayout from '../layouts/PublicLayout'
import PrivateLayout from '../layouts/PrivateLayout'
import EmptyPageLayout from '../layouts/EmptyPageLayout'
import withLayout from '../helpers/layout'
import NoPageView from '../views/NoPageView'
import * as authUtils from '../utils/auth'
import { AUTH_QUERY, ME_QUERY } from '../graphql/queries'
import { urls, publicPaths, privatePaths } from '../constants/urls'
import theme from '../constants/theme'

function App() {
  const client = useApolloClient()

  const [isLoggedIn, setLoggedIn] = React.useState(authUtils.hasToken())
  const [user, setUser] = React.useState(null)

  const logoutUser = React.useCallback(() => {
    authUtils.logoutUser(client, true)
  }, [client])

  const loginUser = React.useCallback(
    (response) => {
      authUtils.loginUser(client, response)
    },
    [client],
  )

  // listen on ApolloClient errorLink: UNAUTHENTICATED error via cache control
  const handleAuthQueryCallback = React.useCallback(
    ({ complete, result }) => {
      if (complete && result.auth.isLoggedIn !== isLoggedIn) {
        setLoggedIn(result.auth.isLoggedIn)
        if (!result.auth.isLoggedIn) {
          setUser(null)
        }
      }
    },
    [isLoggedIn],
  )
  React.useEffect(() => {
    client.cache.watch({
      query: AUTH_QUERY,
      optimistic: true,
      callback: handleAuthQueryCallback,
    })
  }, [client, handleAuthQueryCallback])

  React.useEffect(() => {
    const fetchUser = async () => {
      try {
        // important to disable cache
        const {
          data: { me: userData },
        } = await client.query({ query: ME_QUERY, fetchPolicy: 'network-only' })
        setUser(userData)
      } catch (err) {
        console.log('[Auth Me Query Error]', err.message)
        logoutUser() // logout user when can't load ME query (eg.: user deleted, deactivated)
      }
    }
    if (isLoggedIn && !user) {
      fetchUser()
    }
  }, [client, isLoggedIn, user, logoutUser])

  const value = React.useMemo(
    () => ({ user, isLoggedIn, loginUser, logoutUser }),
    [user, loginUser, logoutUser, isLoggedIn],
  )

  return (
    <MuiThemeProvider theme={theme}>
      <AuthContext.Provider value={value}>
        <SnackbarProvider maxSnack={3}>
          <ModalProvider>
            <CssBaseline />
            <Router>
              <Switch>
                {/* Public routes */}
                <ProtectedRoute
                  shouldRedirect={isLoggedIn}
                  redirectTo={urls.root}
                  exact
                  path={publicPaths}
                  component={withLayout(AppPublicRoutes, PublicLayout)}
                />
                {/* Private routes */}
                <Route
                  path={privatePaths}
                  exact
                  component={withLayout(AppProtectedRoutes, PrivateLayout)}
                />

                <Route
                  render={(props) =>
                    withLayout(
                      NoPageView,
                      EmptyPageLayout,
                    )({ ...props, isLoggedIn })
                  }
                />
              </Switch>
            </Router>
          </ModalProvider>
        </SnackbarProvider>
      </AuthContext.Provider>
    </MuiThemeProvider>
  )
}

export default App
