import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { paths } from '../constants/urls'
import PageLoading from './PageLoading'

// Code splitting
const LoginView = React.lazy(() => import('../views/LoginView'))
const ResetPasswordView = React.lazy(() => import('../views/ResetPasswordView'))
const ConfirmPasswordView = React.lazy(() => import('../views/ConfirmPasswordView'))
const AcceptInvitationView = React.lazy(() => import('../views/AcceptInvitationView'))


export default function AppPublicRoutes() {
  return (
    <React.Suspense fallback={<PageLoading />}>
      <Switch>
        <Route exact path={paths.login} component={LoginView} />
        <Route exact path={paths.resetPassword} component={ResetPasswordView} />
        <Route exact path={paths.confirmPassword} component={ConfirmPasswordView} />
        <Route exact path={paths.acceptInvitation} component={AcceptInvitationView} />
      </Switch>
    </React.Suspense>
  )
}
